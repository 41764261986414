export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-1",
      BUCKET: "chait-notes-app-uploads"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://rx61zo9o3k.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_VfM5ihBTS",
      APP_CLIENT_ID: "43ktga9qaovajmtmtdaaiqn4a1",
      IDENTITY_POOL_ID: "us-east-1:99113110-8131-41cf-9ab2-304173e8ff5e"
    }
  };